body.tox-fullscreen .tox.tox-tinymce.tox-fullscreen {
  z-index: 1401;
}

.tox.tox-silver-sink.tox-tinymce-aux {
  z-index: 1401;
}

.file-upload {
  display: block;
  text-align: center;
  font-size: 16px;
  margin-top: 20px;
}

.file-upload .file-select {
  display: block;
  border: 1px solid #ced4da;
  color: #34495e;
  cursor: pointer;
  height: 42px;
  line-height: 40px;
  text-align: left;
  background: #FFFFFF;
  overflow: hidden;
  position: relative;
}

.file-upload .file-select .file-select-button {
  background: #dce4ec;
  padding: 0 10px;
  display: inline-block;
  height: 40px;
  line-height: 40px;
}

.file-upload .file-select .file-select-name {
  line-height: 40px;
  display: inline-block;
  padding: 0 10px;
}

.file-upload .file-select:hover {
  border-color: #34495e;
  transition: all .2s ease-in-out;
  -moz-transition: all .2s ease-in-out;
  -webkit-transition: all .2s ease-in-out;
  -o-transition: all .2s ease-in-out;
}

.file-upload .file-select:hover .file-select-button {
  background: #34495e;
  color: #FFFFFF;
  transition: all .2s ease-in-out;
  -moz-transition: all .2s ease-in-out;
  -webkit-transition: all .2s ease-in-out;
  -o-transition: all .2s ease-in-out;
}

.file-upload.active .file-select {
  border-color: #3fa46a;
  transition: all .2s ease-in-out;
  -moz-transition: all .2s ease-in-out;
  -webkit-transition: all .2s ease-in-out;
  -o-transition: all .2s ease-in-out;
}

.file-upload.active .file-select .file-select-button {
  background: #3fa46a;
  color: #FFFFFF;
  transition: all .2s ease-in-out;
  -moz-transition: all .2s ease-in-out;
  -webkit-transition: all .2s ease-in-out;
  -o-transition: all .2s ease-in-out;
}

.file-upload .file-select input[type=file] {
  /* z-index: 100; */
  cursor: pointer;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  filter: alpha(opacity=0);
}

.file-upload .file-select.file-select-disabled {
  opacity: 0.65;
}

.file-upload .file-select.file-select-disabled:hover {
  cursor: default;
  display: block;
  border: 2px solid #dce4ec;
  color: #34495e;
  cursor: pointer;
  height: 40px;
  line-height: 40px;
  margin-top: 5px;
  text-align: left;
  background: #FFFFFF;
  overflow: hidden;
  position: relative;
}

.file-upload .file-select.file-select-disabled:hover .file-select-button {
  background: #dce4ec;
  color: #666666;
  padding: 0 10px;
  display: inline-block;
  height: 40px;
  line-height: 40px;
}

.file-upload .file-select.file-select-disabled:hover .file-select-name {
  line-height: 40px;
  display: inline-block;
  padding: 0 10px;
}
